<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <ImagePreview class="col-auto" v-for="file in files" :image="file" :key="file.code" @remove="removeFile" @replace="replaceFile" />
      <div class="col">
        <Uploader ref="uploader" :max="maxFiles" v-model="files" :uploadApiEndpoint="startUpload" :allowedFiles="['image/*']">
          Scegli immagine rappresentativa
        </Uploader>
      </div>
    </div>

    <div class="row">
      <FormInputTextWidget
        class="col-12"
        label="Nome"
        v-model="project.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />
    </div>

    <div class="row">
      <FormInputTextareaWidget
        class="col-12"
        not-required
        label="Note"
        v-model="project.notes"
      />
    </div>

    <div class="row">
      <FormInputCheckboxWidget
        class="col-12"
        v-model="project.hasModuleVisea"
        not-required
      >Il modulo ViSEA è attivo</FormInputCheckboxWidget>
    </div>

    <div class="row">
      <div class="col-12 mt-4 d-flex justify-content-between">
        <div>
          <button class="btn btn-outline-danger font-weight-bold" @click.prevent="removeProject" v-if="edit">Cancella progetto</button>
        </div>
        <button class="btn btn-primary px-5 font-weight-bold">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate';
import uploadFilesMixin from '@/libs/Uploader/mixins/uploadFiles';
import rolesMixin from '@/mixins/roles';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';

export default {
  name: 'project-form',
  mixins: [uploadFilesMixin, validateMixin, rolesMixin, confirmWithModalMixin],
  components: {
    FormContainer: () => import('@/views/components/Form/FormContainer'),
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert'),
    FormInputTextWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    FormInputCheckboxWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputCheckboxWidget'),
    FormInputTextareaWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputTextareaWidget'),
    Uploader: () => import('@/libs/Uploader/components/Uploader'),
    ImagePreview: () => import('@/libs/Uploader/components/ImagePreview'),
  },
  props: {
    project: {
      type: Object,
      default: () => ({
        name: null,
        hasModuleVisea: false,
        notes: null,
        image: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      fixedMaxFiles: 1,
      files: this.project.image ? [{ ...this.project.image }] : [],
    };
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      if (!this.project.name) {
        this.addError('Inserisci il nome', 'name');
      }

      if (this.files.length === 0) {
        this.addError('Selezionare immagine rappresentativa', 'image');
      }

      return !this.hasErrors;
    },
    submit () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }

      if (!this.uploadsFinishedAndReadyToSubmit()) {
        this.addError('image', 'Caricamento immagine in corso');

        return;
      }

      const projectToReturn = { ...this.project };
      projectToReturn.image = this.filesToSubmit.length > 0 ? { ...this.filesToSubmit[0] } : null;

      this.$emit('submitForm', projectToReturn);
    },
    removeProject () {
      this.confirm('Cancellare il progetto e tutti i moduli collegati?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.removeProject(this.project.id)
            .then(() => {
              this.$router.replace({ name: 'projects.list' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
  },
  watch: {
    'project.image': function (newValue) {
      this.files = newValue ? [{ ...newValue }] : [];
    },
  },
};

</script>
